<template>
    <div class="p-2 md:max-w-[670px] mx-auto">
        <!-- Header with Icon -->
        <div class="flex items-end space-x-2 mb-2">
            <div class="w-10 h-10 mb-1">
                <img src="@/assets/icons/fire.gif" alt="Fire Icon" class="w-full h-full" />
            </div>
            <h2 class="text-xl font-semibold text-white">
                อัตราเกมแตก {{ currentDate }}
            </h2>
        </div>

     <!-- Tab Menu -->
     <div class="flex justify-center items-center p-2 rounded-full mb-0 backdrop: space-x-2">
            <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = tab.name" :class="{
                'bg-[#ffe600] text-black border-2 border-[#EECE61]':
                    activeTab === tab.name,
                'bg-gradient-to-br from-[#172f82] to-[#180650] text-white border border-[#EECE61] hover:bg-[#ffe600] hover:text-black':
                    activeTab !== tab.name,
            }" class="flex items-center w-full justify-center px-3 py-1 transition rounded-full mb-2 flex-nowrap">
                <img :src="tab.icon" alt="tab icon" class="w-4 h-4 mr-2" />
                <span class="text-sm flex-grow text-center whitespace-nowrap">{{
                    tab.label
                    }}</span>
            </button>
        </div>

        <!-- Game List in Grid -->
        <div class="grid grid-cols-4 gap-2">
            <div v-for="(game, index) in filteredGameList" :key="index" class="shadow-lg">
                <!-- Click to navigate to game detail -->
                <div @click="viewGameDetail(game)" class="cursor-pointer mb-2">
                    <img :src="game.image" alt="Game Image" class="w-full h-auto" />
                </div>

                <!-- Progress Bar -->
                <div class="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
                    <div class="h-full text-center text-[0.625rem] text-black font-bold flex items-center justify-center moving-background"
                        :style="{ width: `${game.breakRate}%` }">
                        อัตราแตก {{ game.breakRate }}%
                    </div>
                </div>
            </div>
        </div>


        <!-- Baccarat Table Display -->
    <div v-if="activeTab === 'baccarat'" class="grid grid-cols-3 gap-1">
        <div v-for="(table, index) in baccaratTables" :key="index" class="p-1 bg-gradient-to-br from-blue-700 to-blue-500 rounded-lg shadow-lg text-center text-white">
            <h3 class="text-sm font-bold mb-2">{{ table.name }}</h3>
            <div class="mb-3 w-full flex justify-center items-center">
                <img :src="table.image" alt="Table Image" class="w-30 h-10" />
            </div>
            
            <div class="flex justify-center items-center mb-2 space-x-1">
                <div class="flex items-center justify-center w-8 h-4 bg-red-600 text-white rounded-full text-sm">
                    B
                </div>
                <div class="flex items-center justify-center  w-8 h-4 text-white rounded-full text-sm">
                    {{ table.B }}
                </div>
                <div class="flex items-center justify-center  w-8 h-4 bg-blue-600 text-white rounded-full text-sm">
                    P
                </div>
                <div class="flex items-center justify-center  w-8 h-4  text-white rounded-full text-sm">
                    {{ table.P }}
                </div>
                <div class="flex items-center justify-center  w-8 h-4 bg-green-600 text-white rounded-full text-sm">
                    T
                </div>
                <div class="flex items-center justify-center  w-8 h-4  text-white rounded-full text-sm">
                    {{ table.T }}
                </div>
            </div>
            <div class="text-sm  font-bold">
                อัตราชนะ: {{ table.winRate }}%
            </div>

            <button @click="navigateToLink" class="bg-yellow-500 text-black text-xs font-bold py-2 px-3 rounded-2xl shadow-lg mb-2">
                คลิกเพื่อเข้าเล่น
            </button>
        </div>
    </div>


    </div>
</template>


<script>
import { defineComponent } from "vue";

// ตัวอย่างข้อมูลพื้นฐานสำหรับแต่ละรายการ
const pgGamesData = [
    "Yaksa of Honor",
    "Shark Hunter",
    "Futebol Fever",
    "Chicken Run",
    "Zombie Invasion",
    "Anubis Wrath",
    "Mystic Potion",
    "Cash Mania",
    "Wild Ape",
    "Pinata Wins",
    "Gemstones Gold",
    "Fortune Dragon",
    "Dragon Hatch",
    "Werewolves Hunt",
    "Tsar Treasures",
    "Mafia Mayhem",
    "Forge of Wealth",
    "Wild Hest Cashout",
    "Ultimate Striker",
    "Ninja Raccoon Frenzy",
    "Gladiators Glory",
    "Safari Wilds",
    "Cruise Royale",
    "Cover Lady",
    "Fruity Candy",
    "Super Golf Drive",
    "Mystical Treasures",
    "Songkarn Splash",
    "Bakery Bonanza",
    "Hawaiian Tiki",
    "Fortune Rabbit",
    "Rave Party Fever",
    "Midas Fortune",
    "Asgardian Rising",
    "Diner Delights",
    "Alchemy Gold",
    "Totem Wonders",
    "Prosperity Fortune Tree",
    "Wild Bounty Showdown",
    "Wild Coaster",
    "Lucky Piggy",
    "Win Win Fish Prawn Crab",
    "Legend of Perseus",
    "Speed Winner",
    "Tiger Fortune",
    "Butterfly Blossom",
    "Battleground Royale",
    "Rooster Rumble",
    "Garuda Gems",
    "Destiny of Sun and Moon",
    "The Queen's Banquet",
    "Mask Carnival",
    "Oriental Prosperity",
    "Cocktail Nights",
    "Emoji Riches",
    "Spirited Wonders",
    "Supermarket Spree",
    "Raider Jane's Crypt of Fortune",
    "Jurassic Kingdom",
    "Buffalo Win",
    "Monkey King",
    "Ways of the Qilin",
    "Heist Stakes",
    "Wild Bandito",
    "Crypto Gold",
    "Mermaid Riches",
    "Rise of Apollo",
    "Candy Bonanza",
    "Fotune Fox",
    "Winter Infinity Reels",
    "Majestic Treasures",
    "Bali Vacation",
    "Opera Dynasty",
    "Thai River Wonders",
    "Guardians of Ice and Fire",
    "Secrets of Cleopatra",
     "Lucky Neko",
    "Jewels of Prosperity",
    "Treasures of Aztec",
    "Galactic Gems",
    "Genie's 3 Wishes",
    "Queen of Bounty",
    "Wild Fireworks",
    "Phoenix Rises",
    "Circus Delight",
    "Dreams of Macau",
    "Ganesha Fortune", 
    "Mahjong Way 2",
    "Egypt Boox Mystery",
    "Caishen Wins",
    "Candy Burst",
    "Bikini Paradise",
    "Fortune Mouse",
    "Shaolin Soccer",
    "Mahjong Ways",
    "Muay Thai Champion",
    "Dragon Tiger Luck",
    "Gem Saviour Conquest",
    "Flirting Scholar",
    "Leprechaun Riches",
    "Ninja vs Samurai",
    "Vampire's Charm",
    "Dragon Hatch",
    "Captain's Bounty",
    "The Great Icescape",
    "Journey to the Wealth",
    "Double Fortune",
    "Emoerrs Favour",
    "Ganesha Gold",
    "Symbol of Egypt",
    "Jungle Delight",
    "Piggy Gold",
    "Gem Saviour SWORD",
    "Santa's Gift Rush",
    "Prosperity Lion",
    "Mr. Hallow-Win",
    "Legend of Hou Yi",
    "Hip Hop Panda",
    "Dragon Legend ",
    "Hotpot",
    "Fortune Tree",
    "Plushie Frenzy",
    "Win Win Won",
    "Reel Love",
    "Medusa: The Curse of Athena",
    "Medusa II: The Quest of Perseus",
    "Fortune Gods ",
    "Gem Saviour ",
    "Honey Trap of Diao Chan",
];

const allGamesData = [
    { name: "Rave Riches", provider: "NET" },
    { name: "Paddy's Pub", provider: "NET" },
    { name: "T-Rex", provider: "NET" },
    { name: "Ruler of the Dead", provider: "NET" },
    { name: "Mermaid Riches", provider: "PG" },
    { name: "Legendary Luck", provider: "NET" },
    { name: "Chunky Monkey", provider: "NET" },
    { name: "Fired Up", provider: "NET" },
    { name: "Megarun", provider: "NET" },
    { name: "USA Flip", provider: "NET" },
    { name: "Wild Bandolier", provider: "NET" },
    { name: "Rascal Riches", provider: "NET" },
    { name: "Mystic Pharaoh", provider: "NET" },
    { name: "Volcano of Riches", provider: "NET" },
    { name: "Scroll of Seth", provider: "NET" },
    { name: "Sparkling Jewels", provider: "NET" },
    { name: "Sweet Nectar 100", provider: "NET" },
    { name: "Triple Prosperity", provider: "NET" },
    { name: "Razor Sharks", provider: "NET" },
    { name: "Fat Kings", provider: "NET" },
    { name: "Tiki Tumble", provider: "NET" },
    { name: "Mystery Museum", provider: "NET" },
    { name: "Flaming Joker", provider: "NET" },
    { name: "Fat Dracula", provider: "NET" },
    { name: "Shadow Order", provider: "NET" },
    { name: "Mad Carts", provider: "NET" },
    { name: "Joker's Troupe", provider: "NET" },
    { name: "Wild Swarm", provider: "NET" },
    { name: "Jingle Bells", provider: "NET" },
    { name: "Epic Journey", provider: "NET" },
    { name: "Emerald Diamond", provider: "NET" },
    { name: "Elven Magic", provider: "NET" },
    { name: "Dragon's Fire", provider: "NET" },
    { name: "Cinderella", provider: "NET" },
    { name: "Cursed Castle", provider: "NET" },
    { name: "Blue Diamond", provider: "NET" },
    { name: "Big Wheel", provider: "NET" },
    { name: "Ancient Script", provider: "NET" },
    { name: "Magic Beast", provider: "NET" },
    { name: "Hog Wild", provider: "NET" },
];

// ข้อมูลพื้นฐานสำหรับ baccaratTables พร้อมกำหนด provider และชื่อรูปภาพ
const baccaratTablesData = [
    { name: "บาคาร่า C01", provider: "Sexy Gaming" },
    { name: "บาคาร่า C02", provider: "Sexy Gaming" },
    { name: "บาคาร่า C04", provider: "Sexy Gaming" },
    { name: "บาคาร่า A01", provider: "Big Gaming" },
    { name: "บาคาร่า A02", provider: "Big Gaming" },
    { name: "บาคาร่า A03", provider: "Big Gaming" },
    { name: "บาคาร่า 1", provider: "Prag" },
    { name: "บาคาร่า 2", provider: "Prag" },
    { name: "สปีดบาคาร่า  1", provider: "Prag" },
    { name: "สปีดบาคาร่า  2", provider: "Prag" },
    { name: "บาคาร่า สายฟ้า", provider: "EVO" },
    { name: "สปีดบาคาร่าไทย A", provider: "EVO" },
    { name: "บาคาร่า บี", provider: "EVO" },
    { name: "บาคาร่า อ", provider: "EVO" },
    { name: "บาคาร่า D01", provider: "SA Gaming" },
    { name: "บาคาร่า D02", provider: "SA Gaming" },
    { name: "บาคาร่า D03", provider: "SA Gaming" },
    // เพิ่มข้อมูลโต๊ะอื่น ๆ ตามที่ต้องการ
];

export default defineComponent({
    name: "GameList",
    data() {
        return {
            currentDate: this.getCurrentDate(),
            activeTab: "pg",
            tabs: [
                { name: "pg", label: "ค่ายเกม PG", icon: require("@/assets/icons-tab/pg-icon.png") },
                { name: "all", label: "ค่ายเกมรวม", icon: require("@/assets/icons-tab/all-icon.png") },
                { name: "baccarat", label: "สกอร์น่าเล่น บาคาร่า", icon: require("@/assets/icons-tab/baccarat-icon.png") },
            ],
            allPgGames: pgGamesData.map((name, index) => ({
                name,
                image: require(`@/assets/games/${index + 1}.JPEG.png`),
                breakRate: this.randomBreakRate(),
                tab: "pg",
                provider: "PG",
            })), // เก็บข้อมูลเกมทั้งหมดใน allPgGames
            gameList: [], // เก็บข้อมูล 30 เกมที่สุ่มมาแสดง
            gameListAll: allGamesData.map((game, index) => ({
                ...game,
                image: require(`@/assets/games-all/game-all-${index + 1}.png`),
                breakRate: this.randomBreakRate(),
                tab: "all",
            })),
            baccaratTables: baccaratTablesData.map((table) => ({
                ...table,
                B: this.getRandomNumber(20, 50),
                P: this.getRandomNumber(20, 50),
                T: this.getRandomNumber(20, 50),
                winRate: this.getRandomNumber(95, 99),
                image: table.provider === "SA Gaming"
                    ? require("@/assets/Bacarrat/Bacarat-2.png")
                    : table.provider === "Big Gaming"
                    ? require("@/assets/Bacarrat/Bacarat-1.png")
                    : table.provider === "EVO"
                    ? require("@/assets/Bacarrat/Bacarat-3.png")
                    : table.provider === "Prag"
                    ? require("@/assets/Bacarrat/Bacarat-4.png")
                    : table.provider === "Sexy Gaming"
                    ? require("@/assets/Bacarrat/Bacarat-5.png")
                    : table.provider === "WM"
                    ? require("@/assets/Bacarrat/Bacarat-6.png")
                    : require(`@/assets/Bacarrat/Bacarat-1.png`), // กำหนดภาพเริ่มต้นหากไม่มีเงื่อนไข
                tab: "all",
                breakRate: this.randomBreakRate(),
            })),
        };
    },
    computed: {
        filteredGameList() {
            if (this.activeTab === "all") {
                return this.gameListAll; // แสดงเฉพาะเกมในแท็บ "all"
            }
            return this.gameList.filter((game) => game.tab === this.activeTab); // แสดงเฉพาะเกมตามแท็บที่เลือก (pg หรือ baccarat)
        },
    },
    methods: {
        getRandomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        randomBreakRate() {
            return Math.floor(Math.random() * 10) + 90; // ฟังก์ชันสุ่มอัตราเกมแตกระหว่าง 90 ถึง 99
        },
        getCurrentDate() {
            const now = new Date();
            const options = {
                timeZone: "Asia/Bangkok",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            };
            return now.toLocaleDateString("en-GB", options);
        },
        navigateToLink() {
            window.location.href = "https://mr79.net/";
        },
        viewGameDetail(game) {
            this.$router.push({
                name: "game-detail",
                params: {
                    gameName: game.name,
                    gameImage: game.image,
                    gameProvider: game.provider, // ส่ง provider ของแต่ละเกม
                },
            });
        },
        shuffleGameList() {
            // สุ่มเรียงลำดับเกมจาก allPgGames และเลือก 30 เกมแรก
            this.gameList = this.allPgGames
                .sort(() => Math.random() - 0.5) // สุ่มเรียงลำดับเกม
                .slice(0, 30); // แสดงเฉพาะ 30 เกม
        },
    },
    mounted() {
        // สุ่มเกมตอนโหลดคอมโพเนนต์ครั้งแรก
        this.shuffleGameList();

        // สุ่มใหม่ทุก 20 วินาที
        this.autoShuffle = setInterval(this.shuffleGameList, 20000);
    },
    beforeUnmount() {
        // ลบ setInterval เมื่อคอมโพเนนต์ถูกทำลาย
        clearInterval(this.autoShuffle);
    },
});

</script>

<style scoped>
.bg-gradient-to-br {
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #EECE61;
}
.moving-background {
    background-image: linear-gradient(306deg,
            #ffc107 25%,
            #ffc107 25%,
            #ffd700 50%,
            #edb304 50%,
            #ffd700 75%,
            #ffc107 75%,
            #ffc107);
    background-size: 80px 100%;
    animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
    0% {
        background-position: 40px 0;
    }

    100% {
        background-position: 0 0;
    }
}
</style>
